import * as React from 'react'
import Layout from '../components/Layout'

const IndexPage = () => {
  return (
    <Layout pageTitle="Digital Nomad Blog">
      <p>Consciousness. The frontier most easily, yet seldom, explored. These are the voyages of some nerd in a van. The continuing mission, to seek out new experiences and insights. To boldly go down rabbit holes and on tangents that might confuse, upset, or bore some while illicit curiosity, intrigue, and wonder in others. Come with me and venture into a world of pure imagination. </p>
    </Layout>
  )
}

export default IndexPage